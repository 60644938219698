
// import { axiosLIO } from 'plugins/axios'
import _ from 'lodash'
import moment from 'moment'
import nformat from 'vue-filter-number-format'

export default {
  name: 'letsb',
  // backend-io methods
  io: { },
  e: {
    homes: {
      comps: {
        compute: (property) => {
          console.log('🦋 =======================')
          console.log(property)

          // debug
          let debugProp = (cprop) => {
            console.log(`
      ${cprop.address.full}
           ⌂ $          : ${nformat(cprop.value.sold.amount.number, '$0,0.00')}
           ⌂ $/sqft     : ${nformat(cprop.value.sold.amount.number / cprop.rooms.sqft.living, '$0,0.00')}
           ⌂ since      : ${moment(cprop.value.sold.timestamp).fromNow(true)} / ${(new Date(cprop.value.sold.timestamp)).toDateString()}
           ⌂ lot        : ${cprop.land.lot.acres / 1000} acres
           ⌂ year       : ${cprop.construction.year}
           ⌂ cons.type  : ${cprop.construction.type} / ${cprop.construction.class} / ${cprop.construction.condition}
           ⌂ basement   : ${cprop.rooms.sqft.basement.finished + cprop.rooms.sqft.basement.unfinished} sqft (${cprop.rooms.sqft.basement.finished / (cprop.rooms.sqft.basement.unfinished + cprop.rooms.sqft.basement.finished)}% finished)
          ——————————————————————
           𝚫 year       : ${property.construction.year - cprop.construction.year}
           𝚫 sqft       : ${cprop.compute.diffs.sqft}
           𝚫 lot        : ${(property.land.lot.acres - cprop.land.lot.acres) / 1000} acres
           𝚫 bedrooms   : ${cprop.compute.diffs.bedrooms}
           𝚫 full_bath  : ${cprop.compute.diffs.full_bathrooms}
           𝚫 half_bath  : ${cprop.compute.diffs.half_bathrooms}
          ——————————————————————
           ~ $          : ${nformat(cprop.compute.adjustments.adjusted.price, '$0,00.00')}
           ~ $/sqft     : ${nformat(cprop.compute.adjustments.adjusted.pricePerSqft, '$0,00.00')}
          ——————————————————————
           ⌘ applyMode  : ${cprop.compute.applyMode}
           ⌘ confidence : ${cprop.compute.confidenceLevel}
           ⌘ distance   : ${cprop.compute.distance} miles
           ⌘ relevancy  : ${cprop.compute.relevanceLevel}
          ——————————————————————
           RATING (TDS) : AAA
           `)
          }

          let comps = property.comps
          let list = comps.list

          let applyModes = {
            AUTO: 0,
            AUTO_YES: 1,
            AUTO_NO: 2,
            USER_YES: 3,
            USER_NO: 4
          }
          let adjustmentConfigs = {
            ADJUSTMENTS_THRESHOLD_S: 100, // small (ok)
            ADJUSTMENTS_THRESHOLD_M: 200, // medium (warning)
            ADJUSTMENTS_THRESHOLD_L: 300, // large (can be used, but confidence level is low)
            ADJUSTMENTS_THRESHOLD_X: 400, // extremely large (should NOT be used) - disable the user's ability to use
            ADJUSTMENTS_DIFF_BY_DETAIL: {
              sqft: [250, 300, 300],
              bedrooms: [0, 1, 2],
              full_bathrooms: [0, 1, 2],
              half_bathrooms: [0, 1, 2]
            },
            ADJUSTMENTS_COST_BY_ROOM: {
              // space: [ low-end, typical, high-end]
              bedrooms: [5000, 10000, 15000],
              full_bathrooms: [4000, 12000, 20000],
              half_bathrooms: [3000, 6000, 10000]
            },
            AQC: {
              // Age/Quality/Condition: [low, medium/typical, high]
              AQC_MEASURE_L: 1000,
              AQC_MEASURE_M: 2000,
              AQC_MEASURE_H: 3000
            }
          }
          let thresholdTest = (diff, thresholdLevels) => {
            diff = Math.abs(diff)
            if (diff <= thresholdLevels[0]) {
              return adjustmentConfigs.ADJUSTMENTS_THRESHOLD_S
            } else if (diff <= thresholdLevels[1]) {
              return adjustmentConfigs.ADJUSTMENTS_THRESHOLD_M
            } else if (diff <= thresholdLevels[2]) {
              return adjustmentConfigs.ADJUSTMENTS_THRESHOLD_L
            }
            // if none apply, return out of bound result (safer)
            return adjustmentConfigs.ADJUSTMENTS_THRESHOLD_X
          }

          // check for comps
          if (!list || !list.length) {
            // not enough market data to compute
            property.value.valuation = {
              avg: false
            }
            return property
          }

          console.log(list)

          // sanatize list
          let sanatizedList = []
          list.forEach(cprop => {
            if (!cprop.value.sold.amount.number) return

            // calculate diffs for each cprop
            let diffs = {
              sqft: property.rooms.sqft.living - cprop.rooms.sqft.living,
              bedrooms: property.rooms.bedrooms - cprop.rooms.bedrooms,
              full_bathrooms: property.rooms.full_bathrooms - cprop.rooms.full_bathrooms,
              half_bathrooms: property.rooms.half_bathrooms - cprop.rooms.half_bathrooms
            }
            let thresholds = {
              sqft: thresholdTest(diffs.sqft, adjustmentConfigs.ADJUSTMENTS_DIFF_BY_DETAIL.sqft),
              bedrooms: thresholdTest(diffs.bedrooms, adjustmentConfigs.ADJUSTMENTS_DIFF_BY_DETAIL.bedrooms),
              full_bathrooms: thresholdTest(diffs.full_bathrooms, adjustmentConfigs.ADJUSTMENTS_DIFF_BY_DETAIL.full_bathrooms),
              half_bathrooms: thresholdTest(diffs.half_bathrooms, adjustmentConfigs.ADJUSTMENTS_DIFF_BY_DETAIL.half_bathrooms)
            }

            // select appropriate comps
            let applyMode = applyModes.AUTO
            if (!cprop.applyMode || cprop.applyMode === applyModes.AUTO) {
              applyMode = applyModes.AUTO_YES
              if (thresholds.sqft >= adjustmentConfigs.ADJUSTMENTS_THRESHOLD_L ||
                thresholds.beds >= adjustmentConfigs.ADJUSTMENTS_THRESHOLD_L ||
                thresholds.full_bathrooms >= adjustmentConfigs.ADJUSTMENTS_THRESHOLD_L ||
                thresholds.half_bathrooms >= adjustmentConfigs.ADJUSTMENTS_THRESHOLD_L
              ) {
                applyMode = applyModes.AUTO_NO
              }
            }

            // calcualte confidenceLevel based on diff thresholds
            let confidenceLevel = adjustmentConfigs.ADJUSTMENTS_THRESHOLD_S
            Object.keys(thresholds).forEach(thresholdType => {
              var threshold = thresholds[thresholdType]
              if (confidenceLevel < threshold) confidenceLevel = threshold
            })
            if (confidenceLevel > adjustmentConfigs.ADJUSTMENTS_THRESHOLD_X) {
              confidenceLevel = adjustmentConfigs.ADJUSTMENTS_THRESHOLD_X
            }

            // calculate relevanceLevel based on distance
            let relevanceLevel = (1 - cprop.compute.distance) * (adjustmentConfigs.ADJUSTMENTS_THRESHOLD_S / confidenceLevel)

            // pick the best 4 comps to do the calculation

            // calculate adjustments
            var costByRoomAdjustments = adjustmentConfigs.ADJUSTMENTS_COST_BY_ROOM
            let adjustments = {
              computed: false,
              adjusted: {}
            }
            if (applyMode === applyModes.AUTO_YES || applyMode === applyModes.USER_YES) {
              // console.log(`[Property ${comp.address}]:`)
              adjustments.adjusted.pricePerSqft = Math.round(cprop.value.sold.amount.number / (property.rooms.sqft.living + diffs.sqft), 2)
              // console.log(`  > [      SOLD] $${comp.soldPrice} @ $${compPricePerSqft}/sqft`)
              // adjust sqft
              adjustments.adjusted.sqft = (diffs.sqft * adjustments.adjusted.pricePerSqft)
              // if (DEV) console.log(`  > [  ADJ:SQFT] $${compAdjustSqft} (𝜟 ${comp.diffs.sqft})`)
              // adjust beds
              adjustments.adjusted.bedrooms = diffs.bedrooms * costByRoomAdjustments.bedrooms[1]
              // if (DEV) console.log(`  > [  ADJ:BEDS] $${compAdjustBeds} (𝜟 ${comp.diffs.beds})`)
              // adjust baths
              adjustments.adjusted.bathrooms = (diffs.half_bathrooms * costByRoomAdjustments.half_bathrooms[1]) + (diffs.full_bathrooms * costByRoomAdjustments.full_bathrooms[1])
              // if (DEV) console.log(`  > [ ADJ:BATHS] $${compAdjustBaths} (𝜟 ${comp.diffs.baths_full}f ${comp.diffs.baths_half}h)`)
              // tally-up adjustments
              adjustments.adjusted.price = cprop.value.sold.amount.number + adjustments.adjusted.sqft + adjustments.adjusted.bedrooms + adjustments.adjusted.bathrooms
              // if (DEV) console.log(`  > [  ADJUSTED] $${adjustedPrice}`)
              // save adjustments
              adjustments.computed = true
            }

            // update cprop with computed data
            cprop.compute = {
              ...cprop.compute,
              ...{
                applyMode,
                diffs,
                thresholds,
                confidenceLevel,
                relevanceLevel,
                adjustments
              }
            }

            // add to sanatized list
            sanatizedList.push(cprop)
          })

          // extract what is applied (automatically or by user)
          let appliedList = _.filter(sanatizedList, cprop => {
            return (cprop.compute.applyMode === applyModes.AUTO_YES || cprop.compute.applyMode === applyModes.USER_YES) &&
            cprop.compute.confidenceLevel <= adjustmentConfigs.ADJUSTMENTS_THRESHOLD_M
          })

          // remove out-of-bound comp prices
          let maxPrice = _.maxBy(appliedList, cprop => cprop.compute.adjustments.adjusted.price)
          maxPrice = maxPrice.compute.adjustments.adjusted.price
          console.log(`${nformat(maxPrice, '$0,0.00')}`)

          appliedList = _.filter(appliedList, cprop => {
            return (cprop.compute.adjustments.adjusted.price / maxPrice) >= 0.6
          })

          // sort by relevanceLevel
          let relevantList = _.sortBy(appliedList, cprop => cprop.compute.relevanceLevel)
          console.log(`relevantList (${relevantList.length}):`)

          // debug relevantList
          relevantList.forEach(cprop => debugProp(cprop))

          // summarize computation
          let summaryMinMax = {
            byPricePerSqft: {
              min: _.minBy(relevantList, cprop => cprop.compute.adjustments.adjusted.pricePerSqft),
              max: _.maxBy(relevantList, cprop => cprop.compute.adjustments.adjusted.pricePerSqft)
            },
            byPrice: {
              min: _.minBy(relevantList, cprop => cprop.compute.adjustments.adjusted.price),
              max: _.maxBy(relevantList, cprop => cprop.compute.adjustments.adjusted.price)
            }
          }
          let summary = {
            pricePerSqft: {
              min: summaryMinMax.byPricePerSqft.min.compute.adjustments.adjusted.pricePerSqft,
              max: summaryMinMax.byPricePerSqft.max.compute.adjustments.adjusted.pricePerSqft,
              avg: _.round(_.meanBy(relevantList, cprop => cprop.compute.adjustments.adjusted.pricePerSqft))
            },
            price: {
              min: summaryMinMax.byPrice.min.compute.adjustments.adjusted.price,
              max: summaryMinMax.byPrice.max.compute.adjustments.adjusted.price,
              avg: _.round(_.meanBy(relevantList, cprop => cprop.compute.adjustments.adjusted.price))
            }
          }
          console.log(summary)

          // grouped by confidenceLevel
          let groupedByConfidenceLevel = {}
          relevantList.forEach(cprop => {
            let cpropConfidenceLevel = cprop.compute.confidenceLevel
            if (!groupedByConfidenceLevel[cpropConfidenceLevel]) {
              groupedByConfidenceLevel[cpropConfidenceLevel] = {
                count: 0,
                list: []
              }
            }
            groupedByConfidenceLevel[cpropConfidenceLevel].list.push(cprop)
            groupedByConfidenceLevel[cpropConfidenceLevel].count = groupedByConfidenceLevel[cpropConfidenceLevel].list.length
          })
          console.log(groupedByConfidenceLevel)

          // grouped by quarter (based on since)
          let groupedByQuarterList = {}
          relevantList.forEach(cprop => {
            // debugProp(cprop)
            //
            let since = moment(cprop.value.sold.timestamp).fromNow(true)
            if (!groupedByQuarterList[since]) {
              groupedByQuarterList[since] = {
                count: 0,
                list: []
              }
            }
            groupedByQuarterList[since].list.push(cprop)
            groupedByQuarterList[since].count = groupedByQuarterList[since].list.length
          })
          // group listings
          let periodGroupings = [{
            group: ['a month', '1 month', '2 months', '3 months'],
            list: []
          }, {
            group: ['4 months', '5 months', '6 months'],
            list: []
          }, {
            group: ['7 months', '8 months', '9 months'],
            list: []
          }, {
            group: ['10 months', '11 months', '12 months', 'a year'],
            list: []
          }]
          periodGroupings.forEach(grouping => {
            grouping.group.forEach(since => {
              if (groupedByQuarterList[since]) {
                grouping.list.push(groupedByQuarterList[since].list)
              }
            })
            grouping.list = grouping.list.flat()
          })
          console.log(periodGroupings)
          //
          property.value.valuation = {
            avg: summary.price.max
          }
          return property
        }
      }

    }
  }
}
